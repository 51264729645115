import React, { useState, useEffect } from 'react';
import { auth } from './firebase';
import { getAuth, signOut } from "firebase/auth";
import LoginScreen from './LoginScreen';
import './SplashScreen.css';
import Icon1 from './assets/icons/cecara.svg'; // Import SVGs
import Icon2 from './assets/icons/dw.svg';
import Denuncia from './components/denuncia/Denuncia';
import MisDenuncias from './components/MisDenuncias';
import Loading from './components/loading/Loading';

function App() {
  const [loading, setLoading] = useState(true);
  const [loadingRequests, setLoadingRequests] = useState(false);
  const [dashboard, setDashboard] = useState(true);
  const [db, setDb] = useState(null);
  const [reasons, setReasons] = useState([]);
  const [animals, setAnimals] = useState([]);
  const [verMisDenuncias, setVerMisDenuncias] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  
  const [refresh, setRefresh] = useState(localStorage.getItem('session_id')); // Dummy state

  const [isOnline, setIsOnline] = useState(navigator.onLine); // Estado inicial basado en el estado de conexión


  useEffect(() => {

    // Define las funciones de los eventos
    const handleOnline = () => {
        console.log('Conexión restaurada');
        setIsOnline(true); // Actualiza el estado
    };

    const handleOffline = () => {
        console.log('Conexión perdida');
        setIsOnline(false); // Actualiza el estado
    };

    // Agrega los eventos al cargar el componente
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Detecta si la PWA ya fue instalada
    window.addEventListener('appinstalled', () => {
      console.log('PWA fue instalada');
      setIsAppInstalled(true); // Oculta el botón si ya fue instalada
    });

    // Detecta el evento 'beforeinstallprompt'
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e); // Guarda el evento para ser usado más tarde
    });


    // Open the IndexedDB database after the component mounts
    const request = indexedDB.open('reportDB', 7); 

    request.onupgradeneeded = event => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("settings")) {
        db.createObjectStore("settings", { keyPath: 'id', autoIncrement: true });
      }
      if (!db.objectStoreNames.contains("animals")) {
        db.createObjectStore("animals", { keyPath: 'id', autoIncrement: true });
      }
      if (!db.objectStoreNames.contains("reasons")) {
        db.createObjectStore("reasons", { keyPath: 'id', autoIncrement: true });
      }
      if (!db.objectStoreNames.contains('reports')) {
          const objectStore = db.createObjectStore('reports', { keyPath: 'id', autoIncrement: true });
          objectStore.createIndex('reportedIndex', 'reported', { unique: false });
      } else {
        const reportsStore = event.target.transaction.objectStore('reports'); // No need for transaction creation here

          // Check if the 'reported' index already exists before creating
          if (!reportsStore.indexNames.contains('reported')) {
              reportsStore.createIndex('reportedIndex', 'reported', { unique: false });
          }
      }
    };

    request.onsuccess = function(event) {
        setDb(event.target.result);
    } 
    request.onerror = function(event) {
      console.error('Error al abrir la base de datos:', event.target.errorCode);
    };

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);


    return () => {
      clearTimeout(timer);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      //unsubscribe();
    };
  }, []);

  useEffect(() =>{
    if (db) {
      if (localStorage.getItem('session_id') !== null && localStorage.getItem('session_id') !== "offline") {
        syncUnreportedItems(); // y dbeeriamos ver que los items fueron realmente guardados por el usuari que se loguea.
      }
      

      // get reasons from indexedDB if any.
      getDataFromIndexedDB('reportDB', 'reasons')
      .then(data => {
        if (data.length === 0) {
          throw Error("no hay items");
        }
        console.log('Data retrieved:', data);
        setReasons(data);
      })
      .catch(error => {
        console.error('Error:', error.message);
        // por algun motivo no esta en cache: vamos a buscarla al backend:
        // o es demasiado viejo el cache (1 semana?)
        fetch(`${apiUrl}/api/v2/reasons`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        }).then(response => {
          if (!response.ok) {
            // fallamos al recuperar los reasons:
            // TODO: retry? popup?
            throw new Error("No connection to BE");
          } else {
            return response.json();
          }
        }).then(data => {
          // guardamos la data en reportDB y en setReasons
          const items = data;
          
          writeArrayToIndexedDB('reportDB', 'reasons', items, true)
          .then((message) => {
            console.log(message);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
          setReasons(items);
        }).catch(e => {
          console.log("data e", e);
        });
      });
      
      // get animals from indexedDB if any.
      getDataFromIndexedDB('reportDB', 'animals')
      .then(data => {
        if (data.length === 0) {
          throw Error("no hay items");
        }
       
        setAnimals(data);
      })
      .catch(error => {
        console.error('Error:', error.message);
        // por algun motivo no esta en cache: vamos a buscarla al backend:
        // o es demasiado viejo el cache (1 semana?)
        fetch(`${apiUrl}/api/v2/animals`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        }).then(response => {
          if (!response.ok) {
            // fallamos al recuperar los reasons:
            // TODO: retry? popup?
            throw new Error("No connection to BE");
          } else {
            return response.json();
          }
        }).then(data => {
          // guardamos la data en reportDB y en setAnimals
          const items = data;
          
          writeArrayToIndexedDB('reportDB', 'animals', items, true)
          .then((message) => {
            console.log(message);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
          setAnimals(items);
        }).catch(e => {
          console.log("data e", e);
        });
      });
    }
  }, [db])

  useEffect(() =>{
    if (db) {
      if (localStorage.getItem('session_id') !== null && localStorage.getItem('session_id') !== "offline") {
        syncUnreportedItems(); // y dbeeriamos ver que los items fueron realmente guardados por el usuari que se loguea.
      }
    }

  }, [refresh])


  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Muestra el prompt de instalación
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('Usuario aceptó instalar la PWA');
        } else {
          console.log('Usuario rechazó la instalación');
        }
        setDeferredPrompt(null); // Limpia el deferredPrompt después del uso
      });
    }
  };


  const handleLoading = (loadingStatus) => {
    
    setLoadingRequests(loadingStatus);
  }

  const writeArrayToIndexedDB = (dbName, storeName, dataArray, freshStart = false) => {
    return new Promise((resolve, reject) => {
      // Open the database
      const request = indexedDB.open(dbName, 7); // Version 1, or adjust if upgrading
  
      request.onsuccess = function(event) {
        const db = event.target.result;
  
        // Start a transaction for readwrite
        const transaction = db.transaction(storeName, 'readwrite');
        const objectStore = transaction.objectStore(storeName);
  
        if (freshStart) {
          // Clear the object store before adding new items
          const clearRequest = objectStore.clear();

          clearRequest.onsuccess = function() {
            console.log('Object store cleared successfully');

            // Now add the new items
            // Add each item in the array to the object store
            if (dataArray) {
              dataArray.forEach(item => {
                const addRequest = objectStore.add(item); // You can also use 'put'
  
                addRequest.onsuccess = function() {
                  console.log('Item added:', item);
                };
  
                addRequest.onerror = function(event) {
                  console.error('Error adding item:', event.target.error);
                };
              });
            }
            

            
          };

          clearRequest.onerror = function(event) {
            reject('Failed to clear object store: ' + event.target.error);
          };
        } else {
          // Now add the new items
            // Add each item in the array to the object store
            dataArray.forEach(item => {
              const addRequest = objectStore.add(item); // You can also use 'put'

              addRequest.onsuccess = function() {
                console.log('Item added:', item);
              };

              addRequest.onerror = function(event) {
                console.error('Error adding item:', event.target.error);
              };
            });
        }
       

  
        // Complete the transaction
        transaction.oncomplete = function() {
          resolve('All items successfully added to IndexedDB');
        };
  
        transaction.onerror = function(event) {
          reject('Transaction failed: ' + event.target.error);
        };
      };
  
      request.onerror = function(event) {
        reject('Database open failed: ' + event.target.error);
      };
    });
  }
  

  function getDataFromIndexedDB(dbName, storeName) {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName);
  
      request.onsuccess = function(event) {
        const db = event.target.result;
  
        try {
          const transaction = db.transaction(storeName, 'readonly');
          const objectStore = transaction.objectStore(storeName);
          const getAllRequest = objectStore.getAll();
  
          getAllRequest.onsuccess = function(event) {
            resolve(event.target.result);
          };
  
          getAllRequest.onerror = function(event) {
            reject(event.target.error);
          };
        } catch (error) {
          reject(new Error(`Object store "${storeName}" not found.`));
        }
      };
  
      request.onerror = function(event) {
        reject(event.target.error);
      };
    });
  }
  

  const getUnreportedItems = () => {
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(['reports'], 'readonly');
        const objectStore = transaction.objectStore('reports');
        const index = objectStore.index('reportedIndex');
        const request = index.getAll("false"); // Get all items where reported = false
        
        request.onsuccess = event => resolve(event.target.result);
        request.onerror = event => reject(event);
      });

  }

  const postItemToAPI = async (item) => {
    const a = await fetch(`${apiUrl}/api/v2/reports`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(item),
      headers: { 'Content-Type': 'application/json', 'Session-Id': localStorage.getItem('session_id') }
    }).then(response => {
      if (!response.ok) {
       console.log('Network response was not ok');
      }
      return response.json();
    });
    return a;
  }

  function deleteAllCookies() {
    // Get all cookies in the current document
    const cookies = document.cookie.split(";");
  console.log("cookes:", cookies);
    // Iterate over each cookie
    for (let i = 0; i < cookies.length; i++) {
      // Get the cookie's name (split at the '=' sign)
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
  
      // Set the cookie's expiration date to the past to delete it
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful, you can redirect or show a message here
        console.log("User signed out.");
        localStorage.clear();
        deleteAllCookies();
        setRefresh(null);
      })
      .catch((error) => {
        // Handle any errors that occur during logout
        console.error("Error during sign out:", error);
      });
  };

  const syncUnreportedItems = () => {
    return getUnreportedItems().then(items => {
      if (items.length === 0) {
        console.log('No items to sync');
        return;
      }

      items.forEach(item => {
        postItemToAPI(item)
          .then(() => {
            // Successfully posted, now mark as reported in IndexedDB
            markItemAsReported(item.id);
          })
          .catch(error => {
            console.error('Failed to sync item', item.id, error);
          });
      });
    }).catch(error => {
      console.error('Failed to load items from database', error);
    });;
  }

  const markItemAsReported = (itemId) => {
    
      const transaction = db.transaction(['reports'], 'readwrite');
      const objectStore = transaction.objectStore('reports');
      const getRequest = objectStore.get(itemId);

      getRequest.onsuccess = event => {
        const item = event.target.result;
        item.reported = "true"; // Mark as reported
        const updateRequest = objectStore.put(item);
        
        updateRequest.onsuccess = () => {
          console.log('Item marked as reported:', itemId);
        };
      };

  }


  const handleOnFinish = () => {
    setDashboard(true);
  }

  if (loading) {
    return  <div className="splash-screen">
              <div className="loading-message">SILENT SONG</div>
              
              <div className="icons gap-24">
                <div className="splash-flex">
                  <img src={Icon1} alt="CECARA" className="icon" />
                  <span className='size14'>CECARA</span>
                </div>
                <div className="splash-flex">
                  <img src={Icon2} alt="DARWOFT" className="icon" />
                  <span className='size14'>DARWOFT</span>
                </div>
              </div>
              <br></br>
              <span className='ss-semibold size14 align-center'>Versión 1.0.2</span>
            </div>;
  }

  if (!refresh) {
    return loadingRequests ? (
      <>
      <Loading/>
      <LoginScreen onLogin={setRefresh}  isAppInstalled={isAppInstalled} deferredPrompt={deferredPrompt} handleInstallClick={handleInstallClick} handleLoading={handleLoading}/>
      </>
    )
    :(<LoginScreen onLogin={setRefresh}  isAppInstalled={isAppInstalled} deferredPrompt={deferredPrompt} handleInstallClick={handleInstallClick} handleLoading={handleLoading}/>)
   
  }


  if (dashboard) {
    return (
      <div className="container">
         <div className="login gap-8 push-to-bottom">
            <h1>SilentSong</h1>
            <h2>La aplicación del CECARA para reportar mortandad de aves</h2>
            <img src="/logo512.png" alt="CECARA"  />
         </div>
      
          <div className="form-denuncia flex-column-wrap gap-16">
            <button class="ss-button size16 " onClick={() => {setDashboard(false); setVerMisDenuncias(false)}} >DENUNCIAR ANIMAL MUERTO</button>
            <button class="ss-button size16" onClick={()=> {setDashboard(false); setVerMisDenuncias(true)}}>MIS DENUNCIAS</button>
            { refresh !== 'offline' && isOnline  &&
             (
              <button class="ss-button size16" onClick={()=> {handleLogout()}}>
                LOGOUT
              </button>) 
            }
            {
              refresh === 'offline' && isOnline && (
                <div className="atention-ss">
                  <span className='ss-semibold size14 align-center'>¡Parece que has estado trabajando offline! <br></br>Si realizaste denuncias, logueate para enviarlas.</span>
                  <button class="ss-button size16" onClick={()=> {setRefresh(null);setDashboard(false); setVerMisDenuncias(false)}}>IR A LOGIN</button>
                </div>
              )
            }
          </div>
      </div>
    );
  }

  return (
    <>
    {loadingRequests && <Loading/>}
    <div className="container">
      {verMisDenuncias ? <MisDenuncias onFinish={handleOnFinish} reasons={reasons} animals={animals}/> :<Denuncia database={db} onFinish={handleOnFinish} reasons={reasons} animals={animals} handleLoading={handleLoading}></Denuncia>}
    </div>
    </>
  );
}

export default App;
