import React from 'react';
import { auth, googleProvider } from './firebase';
import { signInWithPopup, getAuth, signInAnonymously, signOut } from 'firebase/auth';
import './LoginScreen.css';

const Login = ({isAppInstalled, deferredPrompt, handleInstallClick, onLogin, handleLoading}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleOfflineLogin = () => {
    onLogin("offline");
  }
  const handleAnonymousLogin = () => {
    handleLoading(true);
    const auth = getAuth();
    signInAnonymously(auth)
      .then(async (userCredential) => {
        // Signed in successfully
        const user = userCredential.user;

        const token = user.accessToken;

        await fetch(`${apiUrl}/api/v2/users/login`, {
          method: 'POST',
          credentials: 'include',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: token, fromBackend: false })
        }).then((response) => {
          // Ensure the response is fully received before redirecting
          if (response.ok) {
            // cookie setted.
            // Now redirect to the admin page
            return response.json();
          } else {
            console.log('Login failed:', response.status);
            // Step 3: If any error happens, log out from Firebase
            throw new Error('Server authentication failed');
          }
        }).then((data) => {
          handleLoading(false);
          localStorage.setItem('session_id', data.session_id);
          onLogin(data.session_id);
        }).catch(error => {
          console.error('Error:', error);
           // Step 3: If any error happens, log out from Firebase
           throw new Error('Server authentication failed');
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Error signing in anonymously:", errorCode, errorMessage);
       handleLoading(false);
        // Step 3: If any error happens, log out from Firebase
        signOut(auth);
        console.log("User logged out from Firebase due to server failure.");
      });
  };

  const handleGoogleLogin = async () => {
    try {
      handleLoading(true);
      const result = await signInWithPopup(auth, googleProvider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      const token = result.user.accessToken;

      await fetch(`${apiUrl}/api/v2/users/login`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: token, fromBackend: false })
      }).then((response) => {
        // Ensure the response is fully received before redirecting
        if (response.ok) {
          return response.json();
          // cookie setted.
          // Now redirect to the admin page
        } else {
          console.log('Login failed:', response.status);
          // TODO: LOGOUT FROM FIREBASE AND DO NOT ENTER INTO THE APPLICATION.
          // Step 3: If any error happens, log out from Firebase
          throw new Error('Server authentication failed');
        }
      }).then((data) => {
        handleLoading(false);
        localStorage.setItem('session_id', data.session_id);
        onLogin(data.session_id);
      }).catch(error => {
        console.error('Error:', error);
        // TODO: LOGOUT FROM FIREBASE AND DO NOT ENTER INTO THE APPLICATION.
        // Step 3: If any error happens, log out from Firebase
        throw new Error('Server authentication failed');
      });
      // You can now use the token to communicate with your backend or just use the user object as needed.
    } catch (error) {
      // Handle Errors here.
      const errorMessage = error.message;
      console.error('Error during Google login:', errorMessage);
      handleLoading(false);
      // Step 3: If any error happens, log out from Firebase
      await signOut(auth);
      console.log("User logged out from Firebase due to server failure.");
    }
  };

  return (
    <div className="container login gap-8">
      <h1>SilentSong</h1>
      <h2>La aplicación del CECARA para reportar mortandad de aves</h2>
      <img src="/logo512.png" alt="CECARA"  />
      <p className='size12 push-to-bottom'>Nuestra aplicación facilita la denuncia rápida de casos de mortandad de aves, permitiendo a los biólogos del CECARA validar la información y planificar acciones de conservación efectivas.</p>


      { navigator.onLine ? (
        <>
          <button className='ss-google size12' onClick={handleGoogleLogin}>
            <i class="g-normal"></i>
            <legend>Reportar con Google</legend>
          </button>

          
          <button class="ss-button ss-buttom-custom size12" onClick={handleAnonymousLogin}>Reportar de manera anónima</button>
        </>
      ) : (
        <>
          <button class="ss-button ss-buttom-custom size12" onClick={handleOfflineLogin}>Continuar offline</button>
          <span className='ss-regular size12 align-center'>No tienes conectividad. Podés denunciar offline y la próxima vez que te loguees tus denuncias serán enviadas.</span>
        </>
      )}

      <span className='ss-regular size12 align-center'>Reportar es el primer paso para desarrollar estrategías de conservación bien orientadas. ¡Sumáte!</span>
     


     
       {/*  Mostrar el botón solo si no está instalada y hay deferredPrompt */}
       {!isAppInstalled && deferredPrompt && (
          <button className="marginTopAuto" onClick={handleInstallClick} style={{ padding: '10px', fontSize: '16px' }}>
            Instalar aplicación
          </button>
        )}
    </div>
  );
};

export default Login;
