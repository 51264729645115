import React, { useState, useEffect } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step4b from './Step4b';
import Step5 from './Step5';
import Step6 from './Step6';
import { getFormDefinition, storeFormDefinition, checkForUpdate } from '../persistencia/IndexedDB';

function Denuncia({database, onFinish, reasons, animals, handleLoading}) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
      reasonId: '',
      animalId: [],
      knowAnimal: 'NO',
      photoAnimal: [],
      structurePhoto: [],
      latitude: 0,
      longitude: 0, 
      description: '',
      reported: "false",
      created: "--/--/---"
    });
  
    const obtenerFechaActual = () => {
      const fecha = new Date();
    
      const dia = String(fecha.getDate()).padStart(2, '0');  // Agrega un '0' si es menor a 10
      const mes = String(fecha.getMonth() + 1).padStart(2, '0');  // Los meses comienzan desde 0, por lo que se suma 1
      const año = fecha.getFullYear();
    
      return `${dia}/${mes}/${año}`;
    };
    
  
      const handleNextStep = () => {
      setCurrentStep(prev => prev + 1);
    };

    const handleNextStepSkip = () => {
      setCurrentStep(prev => prev + 2);
    };
  
    const handlePreviousStep = (step) => {
      setCurrentStep(typeof step === 'number' ? step : prev => prev - 1);
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value,
      }));
    };

    const handleAnimalsChange = (animals) => {
        setFormData(prev => ({
          ...prev,
          animalId: animals,
        }));
      };
  
    const handlePhotoChange = (imageData, source) => {
      setFormData(prev => ({
        ...prev,
        [source === 'photoAnimal' ? 'photoAnimal' : 'structurePhoto']: [imageData]
        }));
        
    };

    const getLocation = async () => {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              accuracy: position.coords.accuracy,
            });
          },
          (err) => {
            reject(err);
          },
          {
            enableHighAccuracy: true,
            timeout: 20000,
            maximumAge: 0,
          }
        );
      });
    };
  
    const handleSubmit = async () => {


      handleLoading(true);
        try {
         
          if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                switch (result.state) {
                    case 'granted':
                        console.log('Permission granted.');
                        
                        break;
                    case 'prompt':
                        console.log('Permission not yet requested.');
                       
                        break;
                    case 'denied':
                        console.log('Permission denied.');
                        alert('Permiso de ubicación denegado. Por favor, actívalo en la configuración de tu navegador.');
                        handleLoading(false);
                        
                        return;
                    default:
                     break;
                }
            }).catch((error) => {
                console.error('Permissions API error:', error);
                alert('Permiso API de ubicación denegado. Por favor, actívalo en la configuración de tu navegador.');
                handleLoading(false);
                return;
            });
        } else {
            console.warn('Permissions API not supported. Attempting geolocation...');
            alert('Permiso de ubicación denegado. Por favor, actívalo en la configuración de tu navegador.');
            handleLoading(false);
            return;
        }


        const position = await getLocation();  
        formData['latitude'] = position.latitude;
        formData['longitude'] = position.longitude;

        formData['reported'] = "false";
        formData['created'] = obtenerFechaActual();
       
        if (navigator.onLine && localStorage.getItem('session_id') !== null && localStorage.getItem('session_id') !== "offline") {
          // Enviar datos al backend
          await fetch(`${apiUrl}/api/v2/reports`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(formData),
            headers: { 'Content-Type': 'application/json', 'Session-Id': localStorage.getItem('session_id') },
          }).then((res) => {
            if (res.ok) {  // Verifica si el código de estado es 2xx
              formData['reported'] = "true";
              return res.json();  // Si es exitoso, convierte la respuesta a JSON
            } 
          }).catch((err) => {
            alert("El envío del reporte a CECARA falló. Intentaremos nuevamente mas tarde.");
            console.log(err);
          });
        } 

          // Guardar en IndexedDB: no importa si no fue enviada al servidor.
          // la dejamos aca para ver sus "ultimas denuncias" (periodo corto en días.)
      
          const transaction = database.transaction('reports', 'readwrite');
          const objectStore = transaction.objectStore('reports');
          await objectStore.add(formData);  
          handleLoading(false);
          setCurrentStep(7); // Ir al paso de agradecimiento
      } catch (error) {
        handleLoading(false);
        if (error.code) {
          switch (error.code) {
            case 1:
                console.error("El usuario denegó el permiso de ubicación.");
                break;
            case 2:
                console.error("La información de ubicación no está disponible.");
                break;
            case 3:
                alert('Determinar la posición por GPS está insumiendo demasiado tiempo. La solicitud se canceló.');
                console.error("La solicitud de ubicación excedió el tiempo de espera.");
                break;
            default:
                console.error("Error desconocido:", error);
          }
        }
       
      }
   };
  
    return (
      <div className="form-denuncia flex-column">
        {currentStep === 1 && <Step1 formData={formData} handleChange={handleChange} handleNextStep={handleNextStep} reasons={reasons} onFinish={onFinish} />}
        {currentStep === 2 && <Step2 formData={formData} handleChange={handleChange} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} handleNextStepSkip={handleNextStepSkip} onFinish={onFinish} />}
        {currentStep === 3 && <Step3 formData={formData} handleAnimalsChange={handleAnimalsChange} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} animals={animals} onFinish={onFinish} />}
        {currentStep === 4 && <Step4 formData={formData} handlePhotoChange={handlePhotoChange} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} onFinish={onFinish} />}
        {currentStep === 5 && <Step4b formData={formData} handlePhotoChange={handlePhotoChange} handleNextStep={handleNextStep} handlePreviousStep={handlePreviousStep} onFinish={onFinish} />}
        {currentStep === 6 && <Step5 formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} handlePreviousStep={handlePreviousStep} onFinish={onFinish} />}
        {currentStep === 7 && <Step6 onFinish={onFinish} />}
      </div>
    );
  }
  
  export default Denuncia;